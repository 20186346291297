/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import { Fancybox } from '@fancyapps/ui'
import './assets/js/css/ImportCss'

const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-subscribe]')
})
